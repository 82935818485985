<template lang="pug">
div
    Panel.datatable(:header="header")
        ProgressBar(v-if='waiting' mode="indeterminate")
        .ta-center(v-else-if='!tValue.length')
            p Nenhum atendimento encontrado.
        div(v-else)
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="$emit('page', $event)")
            DataTable(:value="tValue" :selectionMode='tableProps?.selectionMode' @row-select="tableProps?.rowSelection")
                Column(v-for='c in tData' :key="c.header" :headerStyle='c.hStyle' :header='c.header' :field='c.field' :bodyStyle='c.bStyle' :sortable='c.sort')
                    template(v-if="c.body" #body='{data}')
                        span(v-for="b in c.body" :style="b.style")
                            Button.custom-table-button(v-if='b.bType == "button"' :icon='b.icon' :key="b.tooltip" v-tooltip.top='b.tooltip' @click="b.action(data)" :class="b.classes" )
                            span(v-if='b.bType == "custom"' v-html="customBody(b.custom, data)" v-tooltip.top='b.tooltip')
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="$emit('page', $event)")

</template>

<style lang="scss">
    .datatable{
        .custom-table-button{
            margin: 10% 0 !important
        }
        td, th {
            overflow-wrap: anywhere;
        }
        .p-paginator {
            margin: .5rem 0 !important;
        }
    }
</style>
<script>

    import * as _ from 'lodash'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Panel from 'primevue/panel'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import ProgressBar from 'primevue/progressbar'
    import Paginator from 'primevue/paginator';
    import wsConfigs from './../../middleware/configs';

    export default {
        components: { Button, Column, DataTable, Paginator, Panel, ProgressBar },
        directives: { tooltip: Tooltip },
        props: {
            header: {
                type: String,
                required: true
            },
            tableProps: {
                type: Object,
                required: false
            },
            waiting: {
                type: Boolean,
                required: true
            },
            tData: {
                type: Array,
                required: true
            },
            tValue: {
                type: Array,
                required: true
            },
            paginator: {
                type: Object,
                required: false,
                default: function() {
                    return {
                        page: this.$route.query.pg || 1,
                        per_page: wsConfigs.paginator_perPage,
                        count: 0
                    }
                }
            }
        },
        methods: {
            customBody (custom, data) {
                return _.isString(custom) 
                            ? _.has(data, custom) 
                                ? data[custom] 
                                : custom 
                            : new DOMParser().parseFromString(custom(data), 'text/html').body.innerHTML
            },
        },
    }
</script>